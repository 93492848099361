const theme = {
  plain: {
    color: "#fffefe",
  },
  styles: [{
    types: ["punctuation", "operator", "interpolation-punctuation", "attr-name", "variable", "hvariable"],
    style: {
      color: "#fffefe"
    }
  }, {
    types: ["comment"],
    style: {
      color: "#c7c7c7"
    }
  }, {
    types: ["function", "changed", "keyword", "inserted"],
    style: {
      color: "#999eff"
    }
  }, {
    types: ["class-name", "class-name-definition", "known-class-name", "class-name-fully-qualified", "number", "boolean"],
    style: {
      color: "#ff33ff"
    }
  }, {
    types: ["deleted", "string", "attr-value", "template-punctuation"],
    style: {
      color: "#59f20d"
    }
  }, {
    types: ["builtin", "prolog", "selector", "type-declaration", "type-hint", "return-type"],
    style: {
      color: "#00fbff"
    }
  }, {
    types: ["property", "atrule", "tag", "char", "constant"],
    style: {
      color: "#f54949"
    }
  }]
};

export default function getTheme(interiorTheme) {
  return theme;
};
