// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "LeftNav-module--cds--assistive-text--sQSqp";
export var cdsVisuallyHidden = "LeftNav-module--cds--visually-hidden--AWuXu";
export var currentItem = "LeftNav-module--current-item--qnSjk";
export var divider = "LeftNav-module--divider--sPg9h";
export var dividerSpace = "LeftNav-module--divider-space--mEDXM";
export var hideFeedback = "LeftNav-module--hide-feedback--QHFpJ";
export var outboundLink = "LeftNav-module--outboundLink--NWhZK";
export var showFeedback = "LeftNav-module--show-feedback--DREn1";
export var sideNavDark = "LeftNav-module--side-nav--dark--t8Jxv";
export var sideNavWhite = "LeftNav-module--side-nav--white--QzPel";
export var skeleton = "LeftNav-module--skeleton--gkqZS";