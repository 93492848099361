// extracted by mini-css-extract-plugin
export var button = "Code-module--button--pUIvF";
export var cdsAssistiveText = "Code-module--cds--assistive-text--WrAzm";
export var cdsVisuallyHidden = "Code-module--cds--visually-hidden--krVZc";
export var container = "Code-module--container--C1FUS";
export var copyButton = "Code-module--copy-button--9ztOw";
export var dark = "Code-module--dark--FWnrr";
export var hideFeedback = "Code-module--hide-feedback--RVKuk";
export var highlight = "Code-module--highlight--SIX68";
export var path = "Code-module--path--yD7nw";
export var pathRow = "Code-module--path-row--zLHbV";
export var row = "Code-module--row--v0OOg";
export var showFeedback = "Code-module--show-feedback--apwEe";
export var showMoreButton = "Code-module--show-more-button--+sWVX";
export var sideBarMinHeight = "Code-module--sideBarMinHeight--xFWTp";
export var sidebar = "Code-module--sidebar--ixcOg";
export var skeleton = "Code-module--skeleton--mSTLw";