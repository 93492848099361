import React, { useCallback } from 'react';
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import options from "./config.json";

function ParticlesBanner() {
  const customInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={customInit}
      options={options}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    />
  );
}

export default React.memo(ParticlesBanner);
