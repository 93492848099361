// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "GifPlayer-module--cds--assistive-text--ra0yy";
export var cdsVisuallyHidden = "GifPlayer-module--cds--visually-hidden--kx8UD";
export var container = "GifPlayer-module--container--5oCoY";
export var controls = "GifPlayer-module--controls--lk4Vh";
export var dark = "GifPlayer-module--dark--fMXae";
export var gifDisplayed = "GifPlayer-module--gif-displayed--g97hT";
export var gifHidden = "GifPlayer-module--gif-hidden--AWSfX";
export var gifInDialog = "GifPlayer-module--gif-in-dialog--NfClm";
export var hideFeedback = "GifPlayer-module--hide-feedback--XGLnP";
export var imgDisplayed = "GifPlayer-module--img-displayed--Ti2RX";
export var imgHidden = "GifPlayer-module--img-hidden--pRBfr";
export var showFeedback = "GifPlayer-module--show-feedback--J9TT2";
export var skeleton = "GifPlayer-module--skeleton--jccDA";