// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Video-module--cds--assistive-text--boSst";
export var cdsVisuallyHidden = "Video-module--cds--visually-hidden--dMzjX";
export var hideFeedback = "Video-module--hide-feedback--P7Mhy";
export var showFeedback = "Video-module--show-feedback--qE8oa";
export var skeleton = "Video-module--skeleton--gFN98";
export var video = "Video-module--video--kzyjq";
export var videoButton = "Video-module--video-button--BwolY";
export var videoContainer = "Video-module--video-container--0hJN9";
export var videoIsPlaying = "Video-module--video--is-playing--B-SlS";
export var vimeo = "Video-module--vimeo--tFkVF";