// extracted by mini-css-extract-plugin
export var card = "MiniCard-module--card--SzosA";
export var cdsAssistiveText = "MiniCard-module--cds--assistive-text--yn4lw";
export var cdsVisuallyHidden = "MiniCard-module--cds--visually-hidden--abQgW";
export var hideFeedback = "MiniCard-module--hide-feedback--+j4OQ";
export var icon = "MiniCard-module--icon--zrZQ2";
export var image = "MiniCard-module--image--wSkvw";
export var showFeedback = "MiniCard-module--show-feedback--SS+JB";
export var skeleton = "MiniCard-module--skeleton--JO9DU";
export var title = "MiniCard-module--title--pIGhQ";
export var wrapper = "MiniCard-module--wrapper--PDWPV";