// extracted by mini-css-extract-plugin
export var caption = "DoDontRow-module--caption--LBEQo";
export var card = "DoDontRow-module--card--VW-0g";
export var cardContent = "DoDontRow-module--card-content--XxHVU";
export var cdsAssistiveText = "DoDontRow-module--cds--assistive-text--ec6W9";
export var cdsVisuallyHidden = "DoDontRow-module--cds--visually-hidden--Dq-qJ";
export var content = "DoDontRow-module--content--h0Mk1";
export var correct = "DoDontRow-module--correct--8HrHB";
export var dark = "DoDontRow-module--dark--RFSW4";
export var description = "DoDontRow-module--description--wX8m4";
export var doDontRow = "DoDontRow-module--do-dont-row--V2dU5";
export var example = "DoDontRow-module--example---uoyB";
export var hideFeedback = "DoDontRow-module--hide-feedback--J3AmM";
export var icon = "DoDontRow-module--icon--EjYze";
export var iconCorrect = "DoDontRow-module--icon-correct--F6rWx";
export var iconIncorrect = "DoDontRow-module--icon-incorrect--yJAAB";
export var image = "DoDontRow-module--image--j3nfI";
export var incorrect = "DoDontRow-module--incorrect--NsTIM";
export var ratio = "DoDontRow-module--ratio--siwXQ";
export var ratio16x9 = "DoDontRow-module--ratio16x9--dRmXp";
export var ratio1x1 = "DoDontRow-module--ratio1x1--pRonu";
export var ratio1x2 = "DoDontRow-module--ratio1x2--Rqqic";
export var ratio2x1 = "DoDontRow-module--ratio2x1--Gv3aT";
export var ratio3x4 = "DoDontRow-module--ratio3x4--VqpRT";
export var ratio4x3 = "DoDontRow-module--ratio4x3--02Pjf";
export var ratio9x16 = "DoDontRow-module--ratio9x16--Xqaz-";
export var showFeedback = "DoDontRow-module--show-feedback--r2Heu";
export var skeleton = "DoDontRow-module--skeleton--rwDYx";
export var text = "DoDontRow-module--text--zo7Pp";
export var title = "DoDontRow-module--title--gsZLB";