import React from "react";
import "./src/styles/app.scss";
import ParticlesBanner from "./src/components/ParticlesBanner";
import { Prism } from "prism-react-renderer";
import { handleSearch } from "./src/util/scroll-search";

(typeof global !== "undefined" ? global : window).Prism = Prism;
require('prismjs/components/prism-csharp');
require('prismjs/components/prism-cshtml');
require('prismjs/components/prism-docker');
require('prismjs/components/prism-haskell');
require('prismjs/components/prism-java');
require('prismjs/components/prism-json5');
require('prismjs/components/prism-lua');
require('prismjs/components/prism-php');
require('prismjs/components/prism-python');
require('prismjs/components/prism-rust');
require('prismjs/components/prism-twig');
require('prismjs/components/prism-typescript');

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
    handleSearch(location, prevLocation);
  } else {
    window.addEventListener('load', () => handleSearch(location, prevLocation))
  }
}

export const wrapRootElement = ({element}) => {
  return <div>
    {element}
    <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '22rem', zIndex: 10, backgroundColor: 'var(--primary-1)'}}>
      <ParticlesBanner/>
    </div>
  </div>
}
