import React from 'react';
import Link from 'gatsby-theme-carbon/src/components/Link';
import { Launch as LaunchIcon } from '@carbon/react/icons';

const ShadowedLink = (props) => {
  let isExternal;

  try {
    isExternal = new URL(props.href).origin !== window.location.origin
  } catch (e) {
    isExternal = false;
  }

  if (isExternal) {
    return <Link {...props} target={'_blank'} rel={'noopener noreferrer'}>
        <span>
          {props.children}
          <LaunchIcon style={{'vertical-align': 'middle', 'margin-left': '4px'}}/>
        </span>
    </Link>
  }

  return <Link {...props} ></Link>
};

export default ShadowedLink;
