import waitForElement from "../wait-for-element";

let matchingNode;
let matchingNodeIterator;

export const handleSearch = async (location, prevLocation) => {
  const searchInput = await waitForElement('#search-input');

  if (prevLocation?.search) {
    searchInput.value = prevLocation.search;
  }

  let searchInputValue = searchInput.value;

  if (searchInputValue) {
    matchingNode = searchForElement(searchInputValue);

    if (matchingNode) {
      scrollToElement(matchingNode);
    }
  }

  searchInput.onkeydown = function (event) {
    if (event.key === 'Enter') {
      location.search = event.target.value;

      return;
    }

    if (event.key !== 'Tab') {
      return;
    }

    matchingNode = searchForElement(event.target.value);

    if (matchingNode) {
      scrollToElement(matchingNode);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return false;
  }

  searchInput.oninput = function (event) {
    matchingNode = searchForElement(event.target.value);

    if (matchingNode) {
      scrollToElement(matchingNode);
    }
  };
}

let searchNodeIterator = (search) => {
  let xpath = "" +
    "//*[" +
    // "substring-after(name(), 'h') > 0 and" +
    "contains(translate(text(), 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'), '" +
    search +
    "')]";

  return document.evaluate(xpath, document, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
}

let previousSearch;
const searchForElement = (input) => {
  let result;

  if (matchingNode && input === previousSearch) {
    try {
      result = matchingNodeIterator.iterateNext();
    } catch (e) {
      matchingNodeIterator = searchNodeIterator(input);

      result = matchingNodeIterator.iterateNext();
    }
  } else {
    matchingNodeIterator = searchNodeIterator(input);

    result = matchingNodeIterator.iterateNext();
  }

  if (result === matchingNode && input === previousSearch) {
    result = matchingNodeIterator?.iterateNext();
  }

  previousSearch = input;

  return result;
}

const scrollToElement = (element) => {
  const yOffset = -120;

  const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

  window.scrollTo({top: y, behavior: 'smooth'});
}
