// extracted by mini-css-extract-plugin
export var addNoScroll = "ImageGallery-module--add-no-scroll--H9SGG";
export var cdsAssistiveText = "ImageGallery-module--cds--assistive-text--9Bx2f";
export var cdsVisuallyHidden = "ImageGallery-module--cds--visually-hidden--hGbOQ";
export var closeButton = "ImageGallery-module--close-button--rppsG";
export var column = "ImageGallery-module--column--aVlh-";
export var figure = "ImageGallery-module--figure--yS15i";
export var firstRightNav = "ImageGallery-module--first-right-nav--vOjnQ";
export var galleryContainer = "ImageGallery-module--gallery-container--LwNTy";
export var galleryGrid = "ImageGallery-module--gallery-grid--M4KFe";
export var galleryRow = "ImageGallery-module--gallery-row--u8Dg2";
export var hideFeedback = "ImageGallery-module--hide-feedback--NPerI";
export var icon = "ImageGallery-module--icon--ou8np";
export var imageButtonWrapper = "ImageGallery-module--image-button-wrapper---Kc11";
export var imageInDialog = "ImageGallery-module--image-in-dialog--UqOor";
export var imageTitle = "ImageGallery-module--image-title--uua8D";
export var inDialogGalleryContainer = "ImageGallery-module--in-dialog-gallery-container--49na+";
export var leftNav = "ImageGallery-module--left-nav--u1I5W";
export var navButtons = "ImageGallery-module--nav-buttons--tMRkB";
export var navButtonsContainer = "ImageGallery-module--nav-buttons-container--07gcQ";
export var rightNav = "ImageGallery-module--right-nav--9y9cp";
export var showFeedback = "ImageGallery-module--show-feedback--PJQNZ";
export var skeleton = "ImageGallery-module--skeleton--7pg87";