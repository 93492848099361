// extracted by mini-css-extract-plugin
export var actionIcon = "SquareCard-module--actionIcon--fmBx1";
export var body = "SquareCard-module--body--XGC93";
export var cdsAssistiveText = "SquareCard-module--cds--assistive-text--p4jlR";
export var cdsVisuallyHidden = "SquareCard-module--cds--visually-hidden--q3KWO";
export var darkMode = "SquareCard-module--darkMode--QnAlo";
export var disabled = "SquareCard-module--disabled--v5Jn8";
export var helperIcon = "SquareCard-module--helperIcon--yWMKS";
export var helperText = "SquareCard-module--helperText--s0Og1";
export var hideFeedback = "SquareCard-module--hide-feedback--w54Ru";
export var showFeedback = "SquareCard-module--show-feedback--erD5s";
export var skeleton = "SquareCard-module--skeleton--mPkjF";
export var squareCard = "SquareCard-module--squareCard--RxmdT";
export var title = "SquareCard-module--title--nnq6Q";
export var titleSmall = "SquareCard-module--titleSmall--KaA1+";