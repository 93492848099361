// extracted by mini-css-extract-plugin
export var abbr = "Markdown-module--abbr--kISQ2";
export var blockquote = "Markdown-module--blockquote--0f3IW";
export var cdsAssistiveText = "Markdown-module--cds--assistive-text--gGrr-";
export var cdsVisuallyHidden = "Markdown-module--cds--visually-hidden--09AC2";
export var h1 = "Markdown-module--h1--L6GUs";
export var h2 = "Markdown-module--h2--1kVyQ";
export var h3 = "Markdown-module--h3--vAnxz";
export var h4 = "Markdown-module--h4--cBGhh";
export var h5 = "Markdown-module--h5--e14ij";
export var h6 = "Markdown-module--h6--ZMnx1";
export var hideFeedback = "Markdown-module--hide-feedback--VmFi1";
export var list = "Markdown-module--list--v752M";
export var listItem = "Markdown-module--list-item--SDjkb";
export var paragraph = "Markdown-module--paragraph--pvDu+";
export var paragraphResponsive = "Markdown-module--paragraph--responsive--4EaKC";
export var showFeedback = "Markdown-module--show-feedback--HY6ik";
export var skeleton = "Markdown-module--skeleton--hObRU";
export var sup = "Markdown-module--sup--9uY5W";